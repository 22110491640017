import Vue from "vue";
import { createRouter, createWebHistory } from 'vue-router'
import VueAnalytics from 'vue-analytics';

import AppHeader from "./layout/AppHeader";
import AppFooter from "./layout/AppFooter";

import Components from "./views/Components.vue";
import Index from "./views/Index.vue";
import AboutUs from "./views/AboutUs.vue";
import Contact from "./views/Contact.vue";
import PrivacyPolicy from "./views/Privacy.vue";
import Quote from "./views/Quote.vue";
import Support from "./views/Support.vue";
import NotFound from "./views/404.vue";

import CommunityMobile from "./views/CommunityMobile.vue";
import ChiroMobile from "./views/ChiroMobile.vue";
import SchoolMobile from "./views/SchoolMobile.vue";
import QuickAlert from "./views/QuickAlert.vue";
import QuickCount from "./views/QuickCount.vue";
import Custom from "./views/Custom.vue";

const router = createRouter({
    linkExactActiveClass: "active",
    history: createWebHistory(),
    routes: [
        {
            path: "/",
            name: "index",
            components: {
                header: AppHeader,
                default: Index,
                footer: AppFooter
            }
        },
        {
            path: "/products/:id",
            redirect: "/:id"
        },
        {
            path: "/aboutus",
            name: "aboutus",
            components: {
                header: AppHeader,
                default: AboutUs,
                footer: AppFooter
            }
        },
        {
            path: "/contact",
            name: "contact",
            components: {
                header: AppHeader,
                default: Contact,
                footer: AppFooter
            }
        },
        {
            path: "/communitymobile",
            name: "communitymobile",
            components: {
                header: AppHeader,
                default: CommunityMobile,
                footer: AppFooter
            }
        },
        {
            path: "/chiropracticmobile",
            name: "chiropracticmobile",
            components: {
                header: AppHeader,
                default: ChiroMobile,
                footer: AppFooter
            }
        },
        {
            path: "/schoolmobile",
            name: "schoolmobile",
            components: {
                header: AppHeader,
                default: SchoolMobile,
                footer: AppFooter
            }
        },
        {
            path: "/quickalert",
            name: "quickalert",
            components: {
                header: AppHeader,
                default: QuickAlert,
                footer: AppFooter
            }
        },
        {
            path: "/quickcount",
            name: "quickcount",
            components: {
                header: AppHeader,
                default: QuickCount,
                footer: AppFooter
            }
        },
        {
            path: "/custom",
            name: "custom",
            components: {
                header: AppHeader,
                default: Custom,
                footer: AppFooter
            }
        },
        {
            path: '/privacy',
            name: "privacy",
            components: {
                header: AppHeader,
                default: PrivacyPolicy,
                footer: AppFooter
            }
        },
        {
            path: "/quote",
            name: "quote",
            components: {
                header: AppHeader,
                default: Quote,
                footer: AppFooter
            }
        },
        {
            path: "/support",
            name: "support",
            components: {
                header: AppHeader,
                default: Support,
                footer: AppFooter
            }
        },
        {
            path: "/img",
            name: "assets"
        },
        {
            path: "/(.*)",
            name: "NotFound",
            components: {
                header: AppHeader,
                default: NotFound,
                footer: AppFooter
            }
        }
    ],
    scrollBehavior: to => {
        if (to.hash) {
            return { selector: to.hash };
        } else {
            return { x: 0, y: 0 };
        }
    }
});

export default router;
