<template>
    <section class="section section section-shaped my-0 overflow-hidden">
        <div class="shape shape-style-1 bg-gradient-warning shape-skew">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        <div class="container py-0">
            <div class="row row-grid align-items-center">
                <div class="col-md-6 order-lg-2 ml-lg-auto">
                    <div class="position-relative pl-md-5">
                        <img src="img/ill/ill-2.svg" class="img-center img-fluid">
                    </div>
                </div>
                <div class="col-lg-6 order-lg-1">
                    <div class="d-flex px-3">
                        <div>
                            <icon name="fas fa-tag" size="lg" class="bg-gradient-white" color="primary" shadow rounded></icon>
                        </div>
                        <div class="pl-4">
                            <h4 class="display-3 text-white">Pricing</h4>
                            <p class="text-white">With no contracts, you can cancel anytime!</p>
                            <p class="text-white">We will invoice at your convience (once a year, once a quarter, or once a month)</p>
                        </div>
                    </div>
                    <card shadow class="shadow-lg--hover mt-5">
                        <div class="d-flex px-3">
                            <div>
                                <icon name="fas fa-box-open" gradient="success" color="white" shadow rounded></icon>
                            </div>
                            <div class="pl-4">
                                <h5 class="title text-success">Initial Development</h5>
                                <p>This is a one time charge to develop the app. This charge won't be due until you are fully satisfied with the app! $349 is the baseline cost to have the app developed especially for your needs. If you want custom features, there may be an additional charge.</p>
                            </div>
                        </div>
                    </card>
                    <card shadow class="shadow-lg--hover mt-5">
                        <div class="d-flex px-3">
                            <div>
                                <icon name="fas fa-redo-alt" gradient="warning" color="white" shadow rounded></icon>
                            </div>
                            <div class="pl-4">
                                <h5 class="title text-warning">Monthly Maintenance</h5>
                                <p>This is the charge to keep your app available for free download by users at any time. $89 a month is the baseline cost to keep the app updated and available for download. If you want custom features, there may be an additional charge.</p>
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
};
</script>
<style>
</style>
