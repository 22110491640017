<template>
    <div class="profile-page">
        <section class="section-profile-cover section-shaped my-0">
            <HeroBG></HeroBG>
            <div class="container shape-container d-flex">
                <div class="col px-0">
                    <div class="row">
                        <div class="col-lg-6">
                            <h1 class="display-3 text-white mt-5">Page Not Found
                                <span>Error 404</span>
                            </h1>
                            <div class="btn-wrapper mt-5">
                                <base-button tag="a"
                                             href="mailto:support@rybel-llc.com"
                                             class="mb-3 mb-sm-0"
                                             type="info"
                                             icon="far fa-envelope">
                                    What page were you looking for?
                                </base-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import HeroBG from "./components/HeroBG";
export default {
  name: "components",
  components: {
      HeroBG
  }
};
</script>
<style>
</style>
