<template>
    <div>

        <div class="position-relative">
            <!-- shape Hero -->
            <section class="section-shaped my-0">
                <HeroBG bgImg="img/products/quick-assets/qc-bg.jpg"></HeroBG>
                <div class="container shape-container d-flex">
                    <div class="col px-0">
                        <div class="row">
                            <div class="col-lg-6">
                                <h1 class="display-3  text-white">Account for Students & Staff
                                    <span>with QuickCount</span>
                                </h1>
                                <p class="lead  text-white">For one adult to keep track of 30 kids is a challenge, keeping track of hundreds of kids is almost mission impossible. QuickCount allows for administration to get a real-time list of who is accounted for during times when this information is most valuable.</p>
                                <div class="btn-wrapper">
                                    <base-button tag="a"
                                                 href="#featureList"
                                                 class="mb-3 mb-sm-0"
                                                 type="info"
                                                 icon="far fa-question-circle">
                                        Learn More
                                    </base-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- 1st Hero Variation -->
        </div>
        <section class="section section-lg pt-lg-0 mt--200">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-12">
                        <div class="row row-grid">
                            <div class="col-lg-4">
                                <card class="border-0" hover shadow body-classes="py-5">
                                    <icon name="fas fa-list-ul" type="primary" rounded class="mb-4">
                                    </icon>
                                    <h6 class="text-primary text-uppercase">Easily Submit Rosters</h6>
                                    <p class="description mt-3">No more filling out paper forms, quickly submit who you have and who is missing with a few taps on your phone</p>
                                </card>
                            </div>
                            <div class="col-lg-4">
                                <card class="border-0" hover shadow body-classes="py-5">
                                    <icon name="fas fa-street-view" type="success" rounded class="mb-4">
                                    </icon>
                                    <h6 class="text-success text-uppercase">Report Unaccompanied People</h6>
                                    <p class="description mt-3">Instantly report if you have somebody extra with your group and an administrator will be notified to assist</p>
                                </card>
                            </div>
                            <div class="col-lg-4">
                                <card class="border-0" hover shadow body-classes="py-5">
                                    <icon name="far fa-clipboard" type="warning" rounded class="mb-4">
                                    </icon>
                                    <h6 class="text-warning text-uppercase">Administrator Reports</h6>
                                    <p class="description mt-3">Administrators can instantly see who is accounted for and who is missing within seconds</p>
                                </card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section section-lg" id="featureList">
            <div class="container">
                <icon name="fas fa-question" class="mb-5" size="lg" type="success" shadow rounded></icon>
                <h3>FAQ</h3>
                <div class="row row-grid align-items-center">
                    <div class="col-md-6 order-md-1">
                        <ul class="list-unstyled mt-5">
                            <li>
                                <h6 class="mt-3 mb-0">When would we need this?</h6>Fire drills, active shooter incidents, special events, etc.
                            </li>
                            <li>
                                <h6 class="mt-3 mb-0">How reliable is this?</h6>QuickCount is built on world class redundent network infrastructure
                            </li>
                            <li>
                                <h6 class="mt-3 mb-0">How secure is this?</h6>We are fully FERPA compliant
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-6 order-md-2">
                        <ul class="list-unstyled mt-5">
                            <li>
                                <h6 class="mt-3 mb-0">How customizable is this?</h6>If you can dream it, we can build it
                            </li>
                            <li>
                                <h6 class="mt-3 mb-0">What is the cost?</h6>We offer discounts based off the size of your organization. Request a quote for more info!
                            </li>
                            <li>
                                <h6 class="mt-3 mb-0">Have more questions?</h6>Email us at <a href="mailto:info@rybel-llc.com?subject=QuickCount">info@rybel-llc.com</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <CallToAtion></CallToAtion>
    </div>
</template>
<script>
import HeroBG from "./components/HeroBGSkew";
import Pricing from "./components/Pricing";
import CallToAtion from "./components/CallToAction";

export default {
  name: "components",
  components: {
      HeroBG,
      Pricing,
      CallToAtion
  }
};
</script>
