<template>
    <section class="section section-lg section-shaped overflow-hidden my-0">
        <div class="shape shape-style-2 shape-default shape-skew">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        <div class="container shape-container py-0 pb-5">
            <div class="row row-grid justify-content-between align-items-center">
                <div class="col-lg-6">
                    <h3 class="display-3 text-white">Have a problem?
                        <span class="text-white">We can solve it!</span>
                    </h3>
                    <p class="lead text-white">Here at Rybel, we specialize in building custom solutions to solve anybody's problems. No matter if you just need a small website or a large-scale engineering challenges with custom hardware, we've done it before. Let us know how we can help you today!</p>
                    <div class="btn-wrapper">
                        <router-link to="/quote" class="btn btn-success">
                            Get a Free Quote Today
                        </router-link>
                    </div>
                </div>
                <div class="col-lg-5 mb-lg-auto">
                    <div class="transform-perspective-right">
                        <img src="img/brand/rybelWhite.png" style="height: 300px">
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {};
</script>
<style>
</style>
