<template>
    <section class="section section-lg pt-0">
        <div class="container">
            <card gradient="primary"
                  no-body
                  shadow-size="lg"
                  class="border-0">
                <div class="p-5">
                    <div class="row align-items-center">
                        <div class="col-lg-8">
                            <h3 class="text-white">Be up and running with 30 days or less!</h3>
                            <p class="lead text-white mt-3">Request a free quote and we'll walk you through the process of getting started.</p>
                        </div>
                        <div class="col-lg-3 ml-lg-auto">
                            <base-button tag="a" href="/quote" type="white" block size="lg">
                                Get a Quote
                            </base-button>
                        </div>
                    </div>
                </div>
            </card>
        </div>
    </section>
</template>
<script>
export default {
};
</script>
<style>
</style>
