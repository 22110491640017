<template>
    <div>

        <div class="position-relative">
            <!-- shape Hero -->
            <section class="section-shaped my-0">
                <HeroBG bgImg="img/products/school-assets/bg.png"></HeroBG>
                <div class="container shape-container d-flex">
                    <div class="col px-0">
                        <div class="row">
                            <div class="col-lg-6">
                                <h1 class="display-3  text-white">Connect Your School
                                    <span>with School Mobile</span>
                                </h1>
                                <p class="lead  text-white">Allow for easy, seamless communication between administration, students, and parents using School Mobile.</p>
                                <div class="btn-wrapper">
                                    <base-button tag="a"
                                                 href="#featureList"
                                                 class="mb-3 mb-sm-0"
                                                 type="info"
                                                 icon="far fa-question-circle">
                                        Learn More
                                    </base-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- 1st Hero Variation -->
        </div>
        <section class="section section-lg pt-lg-0 mt--200">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-12">
                        <div class="row row-grid">
                            <div class="col-lg-4">
                                <card class="border-0" hover shadow body-classes="py-5">
                                    <icon name="fas fa-users" type="primary" rounded class="mb-4">
                                    </icon>
                                    <h6 class="text-primary text-uppercase">Group Based Content</h6>
                                    <p class="description mt-3">Create groups within the school to send specific, personalized notifications to each area of the staff.</p>
                                </card>
                            </div>
                            <div class="col-lg-4">
                                <card class="border-0" hover shadow body-classes="py-5">
                                    <icon name="far fa-calendar" type="success" rounded class="mb-4">
                                    </icon>
                                    <h6 class="text-success text-uppercase">Calendar</h6>
                                    <p class="description mt-3">Display a calendar of important school events, such as football games, school dances, pep rallies, parent nights, or other school-wide functions.</p>
                                </card>
                            </div>
                            <div class="col-lg-4">
                                <card class="border-0" hover shadow body-classes="py-5">
                                    <icon name="far fa-question-circle" type="warning" rounded class="mb-4">
                                    </icon>
                                    <h6 class="text-warning text-uppercase">Custom Features</h6>
                                    <p class="description mt-3">Customize your app with features such as weather information, push notifications, sponsors, campus map, or any other desired features.</p>
                                </card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section section-lg" id="featureList">
            <div class="container">
                <icon name="fas fa-list" class="mb-5" size="lg" type="success" shadow rounded></icon>
                <h3>Awesome Features</h3>
                <div class="row row-grid align-items-center">
                    <div class="col-md-6 order-md-1">
                        <ul class="list-unstyled mt-5">
                            <li>
                                <h6 class="mt-3 mb-0">Push Notifications</h6>Staff can communicate messages easily to all users, or specific subsets
                            </li>
                            <li>
                                <h6 class="mt-3 mb-0">Weather</h6> Provide easy access to the current weather and the forecast for your community
                            </li>
                            <li>
                                <h6 class="mt-3 mb-0">Event Info</h6>Display a list of upcoming events at the school
                            </li>
                            <li>
                                <h6 class="mt-3 mb-0">Community Info</h6>List some great things to do in your local community or highlight Partners in Education
                            </li>
                            <li>
                                <h6 class="mt-3 mb-0">Sponsors</h6>Create revenue by selling App Sponsorships for local businesses or even organizations within the community
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-6 order-md-2">
                        <ul class="list-unstyled mt-5">
                            <li>
                                <h6 class="mt-3 mb-0">Newsletter</h6>Provide easy access to your newsletter in a convenient place
                            </li>
                            <li>
                                <h6 class="mt-3 mb-0">Sports</h6>Provide information about sports teams schedules and results
                            </li>
                            <li>
                                <h6 class="mt-3 mb-0">Social Media Integration</h6>Integrate with your social media presence from the app
                            </li>
                            <li>
                                <h6 class="mt-3 mb-0">Customer Support</h6>Receive complementary customer support with your app
                            </li>
                            <li>
                                <h6 class="mt-3 mb-0">Expert Training</h6>Receive complementary training from product experts to bring your organization up to speed
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <!-- <section class="section bg-secondary">
            <div class="container">
                <div class="row row-grid align-items-center">
                    <div class="col-md-4">
                        <div class="card bg-default shadow border-0">
                            <img v-lazy="'img/products/community-assets/screenshot1.png'" class="card-img-top">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="pl-md-5">
                            <icon name="ni ni-settings" class="mb-5" size="lg" type="warning" shadow rounded></icon>

                            <h3>Other Customers</h3>
                            <p class="lead">Comunity Mobile has been very successful in a number of communities already!</p>
                            <a href="http://onelink.to/ezd4qb" class="font-weight-bold text-warning mt-5">Download an example of Community Mobile</a>
                        </div>
                    </div>
                </div>
            </div>
        </section> -->
        <Pricing></Pricing>
        <CallToAtion></CallToAtion>
    </div>
</template>
<script>
import HeroBG from "./components/HeroBGSkew";
import Pricing from "./components/Pricing";
import CallToAtion from "./components/CallToAction";

export default {
  name: "components",
  components: {
      HeroBG,
      Pricing,
      CallToAtion
  }
};
</script>
