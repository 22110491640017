<template>
    <header class="header-global">
        <base-nav class="navbar-main" transparent type="" effect="light" expand>
        <router-link slot="brand" class="navbar-brand mr-lg-5" to="/">
            <img src="img/brand/rybelWhite.png" alt="logo" style="height: 60px">
        </router-link>

        <div class="row" slot="content-header" slot-scope="{closeMenu}">
            <div class="col-10 collapse-brand">
                <img src="img/brand/rybelBlack.png" height="50px">
                Rybel LLC
            </div>
            <div class="col-2 collapse-close">
                <close-button @click="closeMenu"></close-button>
            </div>
        </div>

        <ul class="navbar-nav navbar-nav-hover align-items-lg-center">
            <base-dropdown class="nav-item" menu-classes="dropdown-menu-xl">
            <a slot="title" href="#" class="nav-link" data-toggle="dropdown" role="button">
                <i class="ni ni-ui-04 d-lg-none"></i>
                <span class="nav-link-inner--text">Products</span>
            </a>
            <div class="dropdown-menu-inner">
                <router-link to="/communitymobile" class="media d-flex align-items-center">
                    <img class="ni" src="img/brand/communitymobile.png" height="50px">
                    <div class="media-body ml-3">
                        <h6 class="heading mb-md-1" style="color: #21DE8F">Community Mobile</h6>
                    </div>
                </router-link>
                <router-link to="/chiropracticmobile" class="media d-flex align-items-center">
                    <img class="ni" src="img/brand/chiromobile.png" height="50px">
                    <div class="media-body ml-3">
                        <h6 class="heading mb-md-1" style="color: #00ADFD">Chiropractic Mobile</h6>
                    </div>
                </router-link>
                <router-link to="/schoolmobile" class="media d-flex align-items-center">
                    <img class="ni" src="img/brand/schoolmobile.png" height="50px">
                    <div class="media-body ml-3">
                        <h6 class="heading mb-md-1" style="color: #AD7CEC">School Mobile</h6>
                    </div>
                </router-link>
                <router-link to="/quickalert" class="media d-flex align-items-center">
                    <img class="ni" src="img/brand/quickalert.png" height="50px">
                    <div class="media-body ml-3">
                        <h6 class="heading mb-md-1" style="color: #FF3B53">QuickAlert</h6>
                    </div>
                </router-link>
                <router-link to="/quickcount" class="media d-flex align-items-center">
                    <img class="ni" src="img/brand/quickcount.png" height="50px">
                    <div class="media-body ml-3">
                        <h6 class="heading mb-md-1" style="color: #FF3B53">QuickCount</h6>
                    </div>
                </router-link>
                <a href="https://ticket.rybel-llc.com" target="_blank" class="media d-flex align-items-center">
                    <img class="ni" src="img/brand/tickevent.png" height="50px">
                    <div class="media-body ml-3">
                        <h6 class="heading mb-md-1" style="color: #0A3E39">Tickevent</h6>
                    </div>
                </a>
                <a href="https://ballot.rybel-llc.com" target="_blank" class="media d-flex align-items-center">
                    <img class="ni" src="img/brand/ballotline.png" height="50px">
                    <div class="media-body ml-3">
                        <h6 class="heading mb-md-1" style="color: #22BFDD">Ballotline</h6>
                    </div>
                </a>
                <router-link to="/custom" class="media d-flex align-items-center">
                    <img class="ni" src="img/brand/custom.png" height="50px">
                    <div class="media-body ml-3">
                        <h6 class="heading mb-md-1" style="color: #00780C">Custom Solutions</h6>
                    </div>
                </router-link>
            </div>
        </base-dropdown>
        <base-dropdown tag="li" class="nav-item">
        <a slot="title" href="#" class="nav-link" data-toggle="dropdown" role="button">
            <i class="far fa-building d-lg-none"></i>
            <span class="nav-link-inner--text">Company</span>
        </a>
        <router-link to="/aboutus" class="dropdown-item">About Us</router-link>
        <router-link to="/contact" class="dropdown-item">Contact Us</router-link>
    </base-dropdown>
</ul>
<ul class="navbar-nav align-items-lg-center ml-lg-auto">
    <li class="nav-item">
        <a class="nav-link nav-link-icon" href="https://itunes.apple.com/us/developer/rybel-llc/id847990414" target="_blank" rel="noopener"  data-toggle="tooltip" title="Apple App Store">
            <i class="fab fa-app-store"></i>
            <span class="nav-link-inner--text d-lg-none">App Store</span>
        </a>
    </li>
    <li class="nav-item">
        <a class="nav-link nav-link-icon" href="https://play.google.com/store/apps/developer?id=Rybel+LLC" target="_blank" rel="noopener" data-toggle="tooltip" title="Google Play Store">
            <i class="fab fa-google-play"></i>
            <span class="nav-link-inner--text d-lg-none">Google Play</span>
        </a>
    </li>
    <li class="nav-item d-none d-lg-block ml-lg-4">
        <a href="/quote" class="btn btn-neutral btn-icon">
            <span class="btn-inner--icon">
                <i class="fas fa-info-circle mr-2"></i>
            </span>
            <span class="nav-link-inner--text">Get A Quote</span>
        </a>
    </li>
</ul>
</base-nav>
</header>
</template>
<script>
import BaseNav from "@/components/BaseNav";
import BaseDropdown from "@/components/BaseDropdown";
import CloseButton from "@/components/CloseButton";

export default {
    components: {
        BaseNav,
        CloseButton,
        BaseDropdown
    }
};
</script>
<style>
</style>
