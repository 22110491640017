<template>
    <div class="shape shape-style-1 shape-dark shape-skew" :style="divStyle">
        <span class="span-15"></span>
        <span class="span-50"></span>
        <span class="span-50"></span>
        <span class="span-75"></span>
        <span class="span-100"></span>
        <span class="span-75"></span>
        <span class="span-50"></span>
        <span class="span-100"></span>
        <span class="span-50"></span>
        <span class="span-100"></span>
    </div>
</template>
<script>
export default {
    props: {
        bgImg: {
            type: String,
            default: "img/brand/bg.png"
        }
    },
    computed: {
        divStyle() {
            return `background-image: url('${this.bgImg}'); background-size: cover; background-position: center; background-repeat: no-repeat`;
        }
    }
};
</script>
<style>
</style>
