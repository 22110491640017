<template>
    <div>

        <div class="position-relative">
            <!-- shape Hero -->
            <section class="section-shaped my-0">
                <HeroBG bgImg="img/products/custom-assets/bg.png"></HeroBG>
                <div class="container shape-container d-flex">
                    <div class="col px-0">
                        <div class="row">
                            <div class="col-lg-6">
                                <h1 class="display-3  text-white">If You Can Dream It
                                    <span>We Can Build It!</span>
                                </h1>
                                <p class="lead  text-white">No matter what challenge your business may face, we can help you with a variety of technical solutions and consulting expertise</p>
                                <div class="btn-wrapper">
                                    <base-button tag="a"
                                                 href="#featureList"
                                                 class="mb-3 mb-sm-0"
                                                 type="info"
                                                 icon="far fa-question-circle">
                                        Learn More
                                    </base-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- 1st Hero Variation -->
        </div>
        <section class="section section-lg pt-lg-0 mt--200">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-12">
                        <div class="row row-grid">
                            <div class="col-lg-4">
                                <card class="border-0" hover shadow body-classes="py-5">
                                    <icon name="fas fa-desktop" type="primary" rounded class="mb-4">
                                    </icon>
                                    <h6 class="text-primary text-uppercase">Websites</h6>
                                </card>
                            </div>
                            <div class="col-lg-4">
                                <card class="border-0" hover shadow body-classes="py-5">
                                    <icon name="fas fa-mobile-alt" type="success" rounded class="mb-4">
                                    </icon>
                                    <h6 class="text-success text-uppercase">Apps</h6>
                                </card>
                            </div>
                            <div class="col-lg-4">
                                <card class="border-0" hover shadow body-classes="py-5">
                                    <icon name="fas fa-server" type="warning" rounded class="mb-4">
                                    </icon>
                                    <h6 class="text-warning text-uppercase">Custom Hardware</h6>
                                </card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <CallToAtion></CallToAtion>
    </div>
</template>
<script>
import HeroBG from "./components/HeroBGSkew";
import Pricing from "./components/Pricing";
import CallToAtion from "./components/CallToAction";

export default {
  name: "components",
  components: {
      HeroBG,
      Pricing,
      CallToAtion
  }
};
</script>
