<template>
    <div class="profile-page">
        <section class="section-shaped my-0 pb-3">
            <HeroBG></HeroBG>
            <div class="container shape-container d-flex">
                <div class="col px-0">
                    <div class="row">
                        <div class="col-lg-6">
                            <h1 class="display-3 text-white mb-5 mt-5">Product Support</h1>
                            <p class="lead  text-white">Let us know which product you are using and what the issue is. We'll get back to you within 24 hours!</p>
                            <div class="btn-wrapper">
                                <base-button tag="a"
                                             href="mailto:support@rybel-llc.com"
                                             class="mb-3 mb-sm-0"
                                             type="info"
                                             icon="far fa-envelope">
                                    Email us at support@rybel-llc.com
                                </base-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import HeroBG from "./components/HeroBG";
export default {
  name: "components",
  components: {
      HeroBG
  }
};
</script>
<style>
</style>
