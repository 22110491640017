<template>
    <div class="profile-page">
        <section class="section-profile-cover section-shaped my-0">
            <HeroBG></HeroBG>
            <div class="container shape-container d-flex">
                <div class="col px-0">
                    <div class="row">
                        <div class="col-lg-6">
                            <h1 class="display-3 text-white mb-5 mt-5">About Us</h1>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section section-skew">
            <div class="container">
                <card shadow class="card-profile mt--300" no-body>
                    <div class="px-4">
                        <div class="row justify-content-center card-profile-image" style="margin-bottom: 150px">
                            <a href="#">
                                <img src="img/about/ryanCobelli.jpg" class="rounded-circle">
                            </a>
                        </div>
                        <div class="text-center mt-5 mb-5">
                            <h3>Ryan Cobelli
                            </h3>
                            <div class="h6 font-weight-300"><i class="ni location_pin mr-2"></i>Founder & Lead Developer</div>
                            <base-button type="default" size="sm" tag="a" href="mailto:ryan@rybel-llc.com">Email</base-button>
                        </div>
                    </div>
                </card>

                <card shadow class="card-profile" no-body style="margin-top: 150px">
                    <div class="px-4">
                        <div class="row justify-content-center card-profile-image" style="margin-bottom: 150px">
                            <a href="#">
                                <img src="img/about/meghanCobelli.jpg" class="rounded-circle">
                            </a>
                        </div>
                        <div class="text-center mt-5 mb-5">
                            <h3>Meghan Cobelli
                            </h3>
                            <div class="h6 font-weight-300"><i class="ni location_pin mr-2"></i>Director of Sales & Marketing</div>
                            <base-button type="default" size="sm" tag="a" href="mailto:meghan@rybel-llc.com">Email</base-button>
                        </div>
                    </div>
                </card>
            </div>
        </section>
    </div>
</template>
<script>
import HeroBG from "./components/HeroBG";
export default {
  name: "components",
  components: {
      HeroBG
  }
};
</script>
<style>
</style>
