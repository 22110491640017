<template>
    <div>

        <div class="position-relative">
            <!-- shape Hero -->
            <section class="section-shaped my-0">
                <HeroBG bgImg="img/products/chiro-assets/bg.jpg"></HeroBG>
                <div class="container shape-container d-flex">
                    <div class="col px-0">
                        <div class="row">
                            <div class="col-lg-6">
                                <h1 class="display-3  text-white">Connect Your Practice
                                    <span>with Chiropractic Mobile</span>
                                </h1>
                                <p class="lead  text-white">Allow for easy, seamless communication between chiropractors and patients using Chiropractic Mobile. </p>
                                <div class="btn-wrapper">
                                    <base-button tag="a"
                                                 href="#featureList"
                                                 class="mb-3 mb-sm-0"
                                                 type="info"
                                                 icon="far fa-question-circle">
                                        Learn More
                                    </base-button>
                                    <base-button tag="a"
                                                 href="http://onelink.to/aat8qk"
                                                 class="mb-3 mb-sm-0"
                                                 target="_blank"
                                                 type="white"
                                                 icon="ni ni-cloud-download-95">
                                        Download Demo
                                    </base-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- 1st Hero Variation -->
        </div>
        <section class="section section-lg pt-lg-0 mt--200">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-12">
                        <div class="row row-grid">
                            <div class="col-lg-4">
                                <card class="border-0" hover shadow body-classes="py-5">
                                    <icon name="far fa-bell" type="primary" rounded class="mb-4">
                                    </icon>
                                    <h6 class="text-primary text-uppercase">Custom Reminders</h6>
                                    <p class="description mt-3">Allow patients to set up reminders for their prescribed exercises, for drinking water throughout the day, or anything else they may need.</p>
                                </card>
                            </div>
                            <div class="col-lg-4">
                                <card class="border-0" hover shadow body-classes="py-5">
                                    <icon name="fas fa-info-circle" type="success" rounded class="mb-4">
                                    </icon>
                                    <h6 class="text-success text-uppercase">Easy Information</h6>
                                    <p class="description mt-3">Put all the information that patients will need right at their fingertips. This can include everything from COVID protocols to where they should park.</p>
                                </card>
                            </div>
                            <div class="col-lg-4">
                                <card class="border-0" hover shadow body-classes="py-5">
                                    <icon name="far fa-question-circle" type="warning" rounded class="mb-4">
                                    </icon>
                                    <h6 class="text-warning text-uppercase">Custom Features</h6>
                                    <p class="description mt-3">Customize your app with features such as directions to your location, appointment creation options, new patient information, or any other desired features.</p>
                                </card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section section-lg" id="featureList">
            <div class="container">
                <icon name="fas fa-list" class="mb-5" size="lg" type="success" shadow rounded></icon>
                <h3>Awesome Features</h3>
                <div class="row row-grid align-items-center">
                    <div class="col-md-6 order-md-1">
                        <ul class="list-unstyled mt-5">
                            <li>
                                <h6 class="mt-3 mb-0">Push Notifications</h6>Staff can communicate messages easily to all users, or specific subsets
                            </li>
                            <li>
                                <h6 class="mt-3 mb-0">Make an Appointment</h6>Allow patients to make an appointment right from the app
                            </li>
                            <li>
                                <h6 class="mt-3 mb-0">Live Wait Times</h6>Show your patients how long it will take to see the doctor
                            </li>
                            <li>
                                <h6 class="mt-3 mb-0">Custom Reminders</h6>Allow patients to be reminded of customizable tasks (take medicine, exercise, etc).
                            </li>
                            <li>
                                <h6 class="mt-3 mb-0">New Patient Info</h6>Allow patients to print out new patient paperwork before they even step foot in the office
                            </li>
                            <li>
                                <h6 class="mt-3 mb-0">Sponsors</h6>Create revenue by selling App Sponsorships
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-6 order-md-2">
                        <ul class="list-unstyled mt-5">
                            <li>
                                <h6 class="mt-3 mb-0">Newsletter</h6>Provide easy access to your periodic information updates
                            </li>
                            <li>
                                <h6 class="mt-3 mb-0">Staff Info</h6>Show patients who they will be interacting with once they enter your office
                            </li>
                            <li>
                                <h6 class="mt-3 mb-0">Social Media Integration</h6>Integrate with your social media presence from the app
                            </li>
                            <li>
                                <h6 class="mt-3 mb-0">Customer Support</h6>Receive complementary customer support with your app
                            </li>
                            <li>
                                <h6 class="mt-3 mb-0">Expert Training</h6>Receive complementary training from product experts to bring your organization up to speed
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section class="section bg-secondary">
            <div class="container">
                <div class="row row-grid align-items-center">
                    <div class="col-md-4">
                        <div class="card bg-default shadow border-0">
                            <img v-lazy="'img/products/chiro-assets/screenshot1.png'" class="card-img-top">
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="pl-md-5">
                            <icon name="fas fa-users" class="mb-5" size="lg" type="warning" shadow rounded></icon>

                            <h3>Other Customers</h3>
                            <p class="lead">Chiropractic Mobile has been very successful in a number of communities already!</p>
                            <a href="http://onelink.to/aat8qk" class="font-weight-bold text-warning mt-5">Download an example of Chiropractic Mobile</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Pricing></Pricing>
        <CallToAtion></CallToAtion>
    </div>
</template>
<script>
import HeroBG from "./components/HeroBGSkew";
import Pricing from "./components/Pricing";
import CallToAtion from "./components/CallToAction";

export default {
  name: "components",
  components: {
      HeroBG,
      Pricing,
      CallToAtion
  }
};
</script>
