<template>
    <footer class="footer">
        <div class="container">
            <div class="row align-items-center justify-content-md-between">
                <div class="col-md-6">
                    <div class="copyright">
                        &copy; {{year}}
                        Rybel LLC & <a href="https://www.creative-tim.com" target="_blank" rel="noopener">Creative Tim</a>
                    </div>
                </div>
                <div class="col-md-6">
                    <ul class="nav nav-footer justify-content-end">
                        <li class="nav-item">
                            <a href="/aboutus" class="nav-link">About Us</a>
                        </li>
                        <li class="nav-item">
                            <a href="/privacy" class="nav-link">Privacy Policy</a>
                        </li>
                        <li class="nav-item">
                            <a href="/support" class="nav-link">Support</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
export default {
  name: 'app-footer',
  data() {
    return {
      year: new Date().getFullYear()
    }
  }
};
</script>
<style>
</style>
