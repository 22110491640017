<template>
    <div>
        <section class="section-hero section-shaped my-0">
            <HeroBG></HeroBG>
            <div class="container shape-container d-flex">
                <div class="col px-0">
                    <div class="row">
                        <div class="customContainer">
                            <span id="homeBanner"><b>Rybel</b> LLC</span>
                            <p class="lead  text-white">Your Vision. Our Solutions.</p>
                            <div class="btn-wrapper">
                                <base-button tag="a"
                                             href="#section2"
                                             class="mb-3 mb-sm-0"
                                             type="info"
                                             icon="fas fa-chevron-down">
                                    See How We Can Help
                                </base-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <examples id="section2"></examples>
    </div>
</template>
<script>
import Examples from "./components/Examples";
import HeroBG from "./components/HeroBG";

export default {
  name: "components",
  components: {
    HeroBG,
    Examples
  }
};
</script>
