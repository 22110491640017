/*!

=========================================================
* Vue Argon Design System - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system/blob/master/LICENSE.md)

* Coded by www.creative-tim.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { createApp, configureCompat } from 'vue'
import App from "./App.vue";
import router from "./router";
import Argon from "./plugins/argon-kit";


const app = createApp({
  render: h => h(App)
})

app.use(Argon);
app.use(router)
app.mount("#app");

configureCompat({
    // ATTR_FALSE_VALUE: false,
    // RENDER_FUNCTION: false
})
