<template>
    <div>

        <div class="position-relative">
            <!-- shape Hero -->
            <section class="section-shaped my-0">
                <HeroBG bgImg="img/products/quick-assets/qa-bg.jpg"></HeroBG>
                <div class="container shape-container d-flex">
                    <div class="col px-0">
                        <div class="row">
                            <div class="col-lg-6">
                                <h1 class="display-3  text-white">Protect Your Facility
                                    <span>with QuickAlert</span>
                                </h1>
                                <p class="lead  text-white">Providing a safe place to work or learn is crucial to your success but this is becoming harder and harder. QuickAlert is the solution to meet these distinctly modern challenges and protect staff members and/or students.</p>
                                <div class="btn-wrapper">
                                    <base-button tag="a"
                                                 href="#featureList"
                                                 class="mb-3 mb-sm-0"
                                                 type="info"
                                                 icon="far fa-question-circle">
                                        Learn More
                                    </base-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- 1st Hero Variation -->
        </div>
        <section class="section section-lg pt-lg-0 mt--200">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-12">
                        <div class="row row-grid">
                            <div class="col-lg-4">
                                <card class="border-0" hover shadow body-classes="py-5">
                                    <icon name="fas fa-exclamation-triangle" type="primary" rounded class="mb-4">
                                    </icon>
                                    <h6 class="text-primary text-uppercase">Help Is Always Accessible</h6>
                                    <p class="description mt-3">A panic button can be affixed anywhere to ensure help is always accessible</p>
                                </card>
                            </div>
                            <div class="col-lg-4">
                                <card class="border-0" hover shadow body-classes="py-5">
                                    <icon name="fas fa-walking" type="success" rounded class="mb-4">
                                    </icon>
                                    <h6 class="text-success text-uppercase">Even on the Go</h6>
                                    <p class="description mt-3">Staff members can also be issues a panic button to attach to their ID badge.</p>
                                </card>
                            </div>
                            <div class="col-lg-4">
                                <card class="border-0" hover shadow body-classes="py-5">
                                    <icon name="far fa-bell" type="warning" rounded class="mb-4">
                                    </icon>
                                    <h6 class="text-warning text-uppercase">Custom Alerts</h6>
                                    <p class="description mt-3">Customize your implementation to your organization's emergency levels & plans.</p>
                                </card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section section-lg" id="featureList">
            <div class="container">
                <icon name="fas fa-question" class="mb-5" size="lg" type="success" shadow rounded></icon>
                <h3>FAQ</h3>
                <div class="row row-grid align-items-center">
                    <div class="col-md-6 order-md-1">
                        <ul class="list-unstyled mt-5">
                            <li>
                                <h6 class="mt-3 mb-0">Who would we need this?</h6>Anywhere that could be a target like churches, schools, work places, etc.
                            </li>
                            <li>
                                <h6 class="mt-3 mb-0">How reliable is this?</h6>QuickAlert is built on world class redundent network infrastructure
                            </li>
                            <li>
                                <h6 class="mt-3 mb-0">How often do batteries have to be replaced?</h6>Each button is rated for thousands of clicks
                            </li>
                            <li>
                                <h6 class="mt-3 mb-0">How can alerts be triggered?</h6>Via a mobile app, static panic buttons or wearable panic buttons
                            </li>
                        </ul>
                    </div>
                    <div class="col-md-6 order-md-2">
                        <ul class="list-unstyled mt-5">
                            <li>
                                <h6 class="mt-3 mb-0">How customizable is this?</h6>If you can dream it, we can build it
                            </li>
                            <li>
                                <h6 class="mt-3 mb-0">What is the cost?</h6>We offer discounts based off the size of your organization. Request a quote for more info!
                            </li>
                            <li>
                                <h6 class="mt-3 mb-0">Have more questions?</h6>Email us at <a href="mailto:info@rybel-llc.com?subject=QuickAlert">info@rybel-llc.com</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <CallToAtion></CallToAtion>
    </div>
</template>
<script>
import HeroBG from "./components/HeroBGSkew";
import Pricing from "./components/Pricing";
import CallToAtion from "./components/CallToAction";

export default {
  name: "components",
  components: {
      HeroBG,
      Pricing,
      CallToAtion
  }
};
</script>
