<template>
    <div class="profile-page">
        <section class="section-shaped my-0 pb-3">
            <HeroBG></HeroBG>
            <div class="container shape-container d-flex">
                <div class="col px-0">
                    <div class="row">
                        <div class="col-lg-6">
                            <h1 class="display-3 text-white mb-5 mt-5">Contact Us</h1>
                            <div class="btn-wrapper">
                                <base-button tag="a"
                                             href="/support"
                                             class="mb-3 mb-sm-0"
                                             type="info"
                                             icon="far fa-question-circle">
                                    Product Support
                                </base-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section" >
            <div class="container">
                <div class="row justify-content-center" id="methods">
                    <div class="col-lg-12">
                        <div class="row row-grid">
                            <div class="col-lg-4">
                                <card class="border-0" hover shadow body-classes="py-5">
                                    <icon name="fas fa-phone" type="primary" rounded class="mb-4">
                                    </icon>
                                    <h6 class="text-primary text-uppercase">Give Us A Call</h6>
                                    <base-button tag="a" href="tel://+14703308933" type="primary" class="mt-4">
                                        +1 (470) 330-8933
                                    </base-button>
                                </card>
                            </div>
                            <div class="col-lg-4">
                                <card class="border-0" hover shadow body-classes="py-5">
                                    <icon name="far fa-envelope" type="success" rounded class="mb-4">
                                    </icon>
                                    <h6 class="text-success text-uppercase">Shoot Us an Email</h6>
                                    <base-button tag="a" href="mailto:info@rybel-llc.co" type="success" class="mt-4">
                                        info@rybel-llc.com
                                    </base-button>
                                </card>
                            </div>
                            <div class="col-lg-4">
                                <card class="border-0" hover shadow body-classes="py-5">
                                    <icon name="far fa-comment" type="warning" rounded class="mb-4">
                                    </icon>
                                    <h6 class="text-warning text-uppercase">Schedule a Product Demo</h6>
                                    <base-button tag="a" href="mailto:demo@rybel-llc.com?subject=Product%20Demo" type="warning" class="mt-4">
                                        demo@rybel-llc.com
                                    </base-button>
                                </card>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import HeroBG from "./components/HeroBG";
export default {
  name: "components",
  components: {
      HeroBG
  }
};
</script>
<style>
</style>
